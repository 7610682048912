import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { USER_JWT_KEY } from '@yi/core/src/brooklyn-jwt/useBrooklynUserJwt';

import { getStorage } from './storage';

const StorageContext = React.createContext<{
  email: string | null | undefined;
  setEmail: (email: string | null) => void;
  removeEmail: () => void;
  token: string | null | undefined;
  setToken: (token: string | null) => void;
  removeToken: () => void;
  cookiesAccepted: string | null | undefined;
  setCookiesAccepted: (cookies: string | null) => void;
}>({
  email: null,
  setEmail: () => {},
  removeEmail: () => {},
  token: null,
  setToken: () => {},
  removeToken: () => {},
  cookiesAccepted: null,
  setCookiesAccepted: () => {},
});

const storage = getStorage();

interface Props {
  onLoginSideEffects?: (() => void)[];
  onLogoutSideEffects?: (() => void)[];
}

/**
 * StorageProvider contain basic logic to retrieval and persist user authorization logic
 * DON"T put any other logic here. If you need localStorage use `getStorage()`
 */
export const StorageProvider: React.FC<PropsWithChildren<Props>> = ({
  children,
  onLoginSideEffects,
  onLogoutSideEffects,
}) => {
  const [token, setToken] = useState(() => {
    return storage.getItem('token');
  });
  const [email, setEmail] = useState(() => {
    return storage.getItem('email');
  });

  const [cookiesAccepted, setCookiesAccepted] = useState(() => {
    return storage.getItem('cookiesAccepted');
  });

  useEffect(() => {
    const prevToken = storage.getItem('token');
    if (token === null) {
      storage.removeItem('token');
      storage.removeItem(USER_JWT_KEY);
      if (prevToken) onLogoutSideEffects?.forEach(f => f());
    } else {
      storage.setItem('token', token);
      if (!prevToken) onLoginSideEffects?.forEach(f => f());
    }
  }, [token, onLoginSideEffects, onLogoutSideEffects]);

  useEffect(() => {
    if (email === null) {
      storage.removeItem('email');
    } else {
      storage.setItem('email', email);
    }
  }, [email]);

  useEffect(() => {
    if (cookiesAccepted === null) {
      storage.removeItem('cookiesAccepted');
    } else {
      storage.setItem('cookiesAccepted', cookiesAccepted);
    }
  });

  const removeEmail = useCallback(() => {
    setEmail(null);
  }, []);

  const removeToken = useCallback(() => {
    setToken(null);
  }, []);

  const removeCookiesAccepted = useCallback(() => {
    setCookiesAccepted(null);
  }, []);

  const value = useMemo(() => {
    return {
      email,
      setEmail,
      removeEmail,
      token,
      setToken,
      removeToken,
      cookiesAccepted,
      setCookiesAccepted,
      removeCookiesAccepted,
    };
  }, [email, removeEmail, removeToken, token, cookiesAccepted, removeCookiesAccepted]);

  return <StorageContext.Provider value={value}>{children}</StorageContext.Provider>;
};

export const useStorage = () => {
  const context = useContext(StorageContext);
  if (typeof context === 'undefined') {
    throw new Error('useStorage must be used within a StorageContext');
  }
  return context;
};
